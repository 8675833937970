@import url(/assets/plugins/bootstrap/css/bootstrap.min.css);
@import url(/assets/plugins/select2/dist/css/select2.min.css);
@import url(/assets/css/style.css);
@import url(/assets/css/custom.css);
@import url(/assets/css/colors/blue.css);

#loaderRoot {
  display: block;
}

.loaderPositionsStyle {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  overflow: auto;
}

.loaderPositionsStyleSub {
  margin: auto;
  max-height: 100%;
}

body [aria-labelledby*="-title"] {
  display: none !important;
}

.cat {
  text-transform: capitalize !important;
}

.name-upload {
  width: 20% !important;
  display: inline !important;
}

.time-input {
  width: 50px !important;
  padding: 5px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.tbl-button,
.tbl-button:hover,
.tbl-button:active,
.tbl-button:focus,
.tbl-button:visited,
.tbl-button:focus-within {
  background-color: #7460ee !important;
  border-radius: 1 !important;
  border: none !important;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4) !important;
}

.tbl-head {
  font-size: 18px;
  color: #7460ee !important;
  font-weight: 500;
}

.custom-file-upload {
  background-color: #7460ee !important;
  border: 1px solid #7460ee;
  border-radius: 5px !important;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  color: #fff;
}

.search-icon {
  position: relative;
  bottom: 32px;
  font-size: 20px;
  right: -88%;
}

.line-background {
  background-color: #7460ee !important;
  color: #fff;
  padding: 10px 10px;
}

.icon-clr {
  font-size: 18px;
  color: #7460ee;
}

.card-bg-color {
  background-color: #f4f4f9;
}

/* .image-parent{
    position: relative;
    margin: 30px;
} */

/* .image-parent {
  width: 10em;
    height: 12em;
    justify-content: center;
    align-items: center;
} */

.cardRatio {
  width: 100%;
  height: 12em;
}

.file-image {
  display: block;
  width: 100%;
  height: auto;
}

.image-parent:hover .middle {
  opacity: 1;
}

.deleteIcon {
  /* display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff; */
  color: red;
  font-size: 20px;
  position: absolute;
  right: 0px;
  top: 15px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.middle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(116, 96, 238, 0.2);
}

.calendercls {
  margin-left: 2%;
  padding-right: 2.357em;
}
.calenderArrowButtons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

.no_counsellor {
  background-color: #5955a5 !important;
  text-align: center;
  color: #ffffff !important;
  margin-bottom: 25px;
}

.previous_counsellor:hover {
  background-color: #a6ce39;
}

.blink_me {
  width: 70%;
}

.cursor_pointer {
  cursor: pointer;
}

.space_between {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.full_width {
  width: 100%;
}

.details_modal {
  border-style: dotted;
  border-radius: 6px;
  margin-bottom: 7px;
  padding: 8px;
}

.text_center {
  text-align: center;
}

.view {
  color: black;
  font-size: large;
  padding: 2px;
}

.txt_area[type="textarea"]::-webkit-input-placeholder {
  padding-top: 20px;
}

.txt_area[type="textarea"]:-ms-input-placeholder {
  padding-top: 20px;
}

.txt_area[type="textarea"]::placeholder {
  padding-top: 20px;
}

tbody + thead {
  display: none;
}

.table.table-hover tbody tr:hover {
  background-color: #248de4;
  color: white;
}

#img_url {
  background: #ddd;
  width: 100px;
  height: 90px;
  display: block;
}

/* table th {
  width: auto !important;
} */

#chartdiv {
  width: 100%;
  height: 500px;
}

.dashboard_cardTitle {
  margin-left: 20px;
  margin-top: 12px;
}

.dashboard_cardtext {
  font-weight: bold;
  font-size: xx-large;
  margin-top: -10px;
}

.dashboard_table {
  /* width: 378px; */
  margin-right: 33px;
  /* border: 1px solid; */
  text-align: center;
}

.dashboard_tablecard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.dashboard_thead {
  background-color: #5955a5 !important;
}

.dashboard_thead th {
  color: white !important;
}

.graph_yearDropdown .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  display: none;
}

#event_history tbody > tr > :nth-child(3) {
  width: 300px;
}

#department_table tbody > tr > :nth-child(4) {
  width: 250px;
}

.feedCounsellerDetails {
  font-weight: 350;
  font-size: small;
}

.disableColor.btn-primary.disabled {
  background-color: grey;
  border-color: grey;
  cursor: default;
}

.availabilityBox {
  width: 11em;
  height: 7em;
  padding: 0px 3px !important;
}

body .p-datatable .p-datatable-thead > tr > th {
  background-color: #007ad9;
  color: white;
}

.p-sortable-column-icon {
  color: white !important;
}

.main_header {
  background-color: darkslateblue !important;
  color: white !important;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
  background-color: none;
  color: white;
}
body .p-datatable .p-sortable-column:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.row-accessories {
  background-color: #007ad9 !important;
  color: white !important;
}

.user_states {
  background-color: #a6ce39 !important;
  color: white !important;
}

.pageviews th {
  background-color: darkslateblue !important;
  color: white !important;
}

.pageviews table.dataTable thead .sorting:after {
  color: white;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: darkslateblue;
}

.editIcon {
  /* display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff; */
  color: #a9a9a9;
  font-size: 20px;
  position: absolute;
  right: 25px;
  top: 15px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.appointment-details-modal {
  border-style: dotted;
  border-radius: 6px;
  margin-bottom: 7px;
  padding: 8px;
}

.with-holiday td:not(.p-datepicker-other-month) span.p-disabled {
  background-color: #ff0000;
  color: #ffffff;
  font-weight: bold;
  border-radius: 3px;
}

.droppable-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid #000;
  background-color: #fff;
}

.bg-skyblue {
  transition: background-color 0.2s ease;
  background-color: #87ceeb;
}

.draggable {
  border: 1px solid #000;
  background-color: #fff;
}

.draggable-bg {
  background-color: #90ee90;
}

.availability-input {
  padding-left: 0;
  text-align: right;
}

/* ----css change for userprofile image-- */
.profile-pic {
  height: 30px;
}

.topbar ul.dropdown-user li .dw-user-box .u-img {
  height: 80px;
}

.topbar ul.dropdown-user li .dw-user-box .u-img img {
  height: 80px;
}

.rc-time-picker-input {
  height: 35px !important;
  font-size: 14px !important;
}

.rc-time-picker-clear {
  top: 6px !important;
}

.rc-time-picker-panel-inner {
  font-size: 14px !important;
}
.rc-time-picker-panel-select-option-disabled {
  display: none;
}
.inputTextField {
  width: 6rem;
  margin: auto;
  display: block;
  height: 1.2rem;
}
.slotDetails {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.counsellorAvailabilityEmail {
  color: #ffffff;
}

